import React from "react"

import Layout from "../layouts"
import SEO from "../components/seo"

/*
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)
*/

const NotFoundPage = () => (
  <div>
    <SEO title="About" keywords={[
      "3dartist",
      "3dmodeling",
      "3d",
      "photorealism",
      "shading",
      "productstudy",
      "3drender",
      "motiongraphics",
      "motiongraphicsdesign",
      "video",
      "videomaking",
      "3danimation",
      "animation",
    ]} />
    <div class="error-404">
      <p className="title"
      >Oops! La pagina che stavi cercando non esiste.</p>
      <p>L'indirizzo potrebbe non essere corretto oppure la pagina potrebbe essere stata rimossa.</p>
    </div>
  </div>
)

export default NotFoundPage
